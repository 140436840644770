<template>
  <div class="response success fadein" ref="response" v-show="response">
    {{ response }}
  </div>
  <div class="response error fadein" ref="errors" v-show="errors.length">
    <template v-for="(error, idx) in errors" :key="idx">
      <template v-if="errors.length > 1">
        <li>{{ error }}</li>
      </template>
      <template v-else>
        {{ error }}
      </template>
    </template>
  </div>
  <div class="loader" v-if="loading"></div>
  <form class="form" ref="form" @submit.prevent="submit_form">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__user.png" alt="" />
        </div>
        <div class="field__title">ID</div>
      </div>
      <template v-if="logged_in">
        <input
          name="login"
          v-model="username"
          placeholder="username"
          type="text"
          class="input"
          required
          minlength="4"
          maxlength="16"
          readonly
        />
      </template>
      <template v-else>
        <input
          name="login"
          v-model="form.username"
          placeholder="username"
          type="text"
          class="input"
          autocomplete
          required
          minlength="4"
          maxlength="16"
        />
      </template>
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__lock.png" alt="" />
        </div>
        <div class="field__title">Password</div>
      </div>
      <input
        v-model="form.password"
        :type="password_reveal ? 'text' : 'password'"
        name="password"
        class="input"
        data-inp-type="inp-2"
        autocomplete=""
        required
        minlength="8"
        maxlength="16"
      />
      <div class="field__tool" @click.prevent="password_reveal = !password_reveal">
        <div class="field__tool-trigger" data-inp-target="inp-2">
          <img src="@/assets/images/icons/ico__close-eye.png" alt="" />
        </div>
      </div>
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__lock.png" alt="" />
        </div>
        <div class="field__title">New password</div>
      </div>
      <input
        v-model="form.password_new"
        name="password_new"
        :type="password_new_reveal ? 'text' : 'password'"
        class="input"
        data-inp-type="inp-2"
        autocomplete=""
        required
        minlength="8"
        maxlength="16"
      />
      <div class="field__tool" @click.prevent="password_new_reveal = !password_new_reveal">
        <div class="field__tool-trigger" data-inp-target="inp-2">
          <img src="@/assets/images/icons/ico__close-eye.png" alt="" />
        </div>
      </div>
    </div>
    <!-- END  field -->

    <div class="protection protection_mb">
      <div class="protection__captcha">
        <vue-recaptcha
          v-if="showRecaptcha"
          :sitekey="recaptcha_sitekey"
          size="normal"
          theme="dark"
          :tabindex="0"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>
      </div>
    </div>

    <div class="form__btn-box">
      <button
        class="button"
        type="submit"
        :class="{ disabled: this.btn_disabled }"
        :disabled="this.btn_disabled"
      >
        <span class="button__content">Change password</span>
      </button>
    </div>
  </form>
</template>

<script>
import cfg_axios from '@/config/cfg_axios';
import axios from 'axios';
import vueRecaptcha from 'vue3-recaptcha2';
import { recaptcha_sitekey } from '@/assets/js/recaptcha';

export default {
  name: 'ChangePassword',
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      recaptcha_sitekey,
      showRecaptcha: true,
      captcha: false,
      form: {
        username: null,
        password: null,
        password_new: null,
      },
      btn_disabled: false,
      response: null,
      errors: [],
      password_reveal: false,
      password_new_reveal: false,
      loading: false,
    };
  },
  computed: {
    username() {
      return this.$store.getters.get_username;
    },
    logged_in() {
      return this.$store.getters.get_logged_in;
    },
  },
  methods: {
    recaptchaVerified() {
      this.captcha = true;
    },
    recaptchaExpired() {
      this.captcha = false;
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.captcha = false;
    },
    async submit_form() {
      this.loading = true;
      this.btn_disabled = true;
      this.errors = [];
      this.response = null;

      if (!this.captcha) {
        this.errors.push('You should verify captcha');
        setTimeout(() => {
          this.btn_disabled = false;
          this.errors = [];
        }, 3000);
        this.loading = false;
        return;
      }

      if (this.errors.length) {
        this.loading = false;
        return;
      }

      const form_data = new FormData(this.$refs.form);

      await axios
        .post(cfg_axios.base_url + '/account/change_password', form_data, {
          headers: cfg_axios.headers,
        })
        .then(response => {
          this.response = response.data;
        })
        .catch(error => {
          this.errors.push(
            error.response.data.messages[Object.keys(error.response.data.messages)[0]]
          );
        })
        .finally(() => {
          this.recaptchaExpired();
          setTimeout(() => {
            this.btn_disabled = false;
            this.errors = [];
            this.response = null;
          }, 10000);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
